import React from 'react'
import Page from '../components/Page/Page'

const Impressum = () => {

    return(
      <Page
        title={'Impressum'}
        renderCallback={() => {
          return(
            <div style={{lineHeight: '1.5'}}>
            <p>Medieninhaber und Herausgeber: Walter Neubauer<br/>
            Anschrift: Lothringerstraße 11, A-1010 Wien, Österreich<br/>
            Telefon: +43 1 718 10 58<br/>
            Telefax: +43 1 908 14 08<br/>
            Medienzweck: Präsentation der Geigenbauwerkstätte und deren Angebote<br/>
            Themen- & Content-Management: Walter Neubauer (walter@neubauer-geigenbau.com)<br/>
            Verantwortlich für den Inhalt dieser Webpage: Walter Neubauer (walter@neubauer-geigenbau.com)<br/>
            Dienstanbieter: Walter Neubauer, Geigenbaumeister<br/>
            Elektronische Postadresse: walter@neubauer-geigenbau.com<br/>
            Aufsichtsbehörde: Wirtschaftskammer Wien, Landesinnung der Musikinstrumentenmacher Wien<br/>
            Kammerzugehörigkeit: Wirtschaftskammer Wien, Landesinnung der Musikinstrumentenmacher Wien<br/>
            Umsatzsteuer-ID-Nummer: ATU42541503<br/>
            </p>
            <p>
            <strong>Haftungshinweis:</strong><br/>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehme ich keinerlei Haftung für die Inhalte externer Links.
            Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
            </p>
            <p>
            <strong>Copyright:</strong><br/>
            Das Copyright für veröffentlichte, vom Site-Betreiber selbst erstellte Objekte bleibt allein beim Site-Betreiber.
            Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne Zustimmung vom Site-Betreiber nicht gestattet.
            </p>
            </div>
          );
        }}
      />
  );
};

export default Impressum
